import { InjectionToken } from '@angular/core';
import { AppConfigurationClient } from '@azure/app-configuration';
import { getEnv } from '@logic-suite/shared/utils';
import { of } from 'rxjs';

export const AZURE_APP_CONFIGURATION_CLIENT = new InjectionToken('Azure app configuration', {
  providedIn: 'root',
  factory: () => {
    const configToken = getEnv('azureAppConfigurationToken');

    try {
      // Will fail if token is not present in the environment.
      return new AppConfigurationClient(configToken);
    } catch (ex) {
      // Return fake client as fallback.
      console.error(ex);
      return {
        listConfigurationSettings: (...args: any) => of({ value: '{}' }),
      } as unknown as AppConfigurationClient;
    }
  },
});
