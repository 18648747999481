import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AZURE_APP_CONFIGURATION_CLIENT } from '@logic-suite/shared/feature-flag/azure-app-confiuration';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { defer, map, Observable, of, Subject } from 'rxjs';

export interface FeatureFlag {
  id: string;
  enabled: boolean;
  description: string;
  conditions: object;
}

interface FeatureFlagState {
  flags: FeatureFlag[];
  loaded: boolean;
  error: string | null;
}

/**
 * Service to manage feature flags stored in App Configuration.
 *
 * TODO(bjhandeland): Look into adding flags a local list.
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private appConfigurationClient = inject(AZURE_APP_CONFIGURATION_CLIENT);
  private applicationStorageService = inject(ApplicationStorageService);

  // state
  private state = signal<FeatureFlagState>({
    flags: [],
    loaded: false,
    error: null,
  });

  // selectors
  flags = computed(() => this.state().flags);
  enabledFlagIds = computed(() =>
    this.flags()
      .filter(flag => flag.enabled)
      .map(flag => flag.id),
  );
  loaded = computed(() => this.state().loaded);
  error = computed(() => this.state().error);

  // sources
  private loadFromStorage$ = this.loadFeatureFlags();
  private fetchFromRemote$ = this.fetchFeatureFlags('noova-energy');
  add$ = new Subject<FeatureFlag>();
  edit$ = new Subject<FeatureFlag>();

  constructor() {
    // region reducers

    this.loadFromStorage$.pipe(takeUntilDestroyed()).subscribe({
      next: (flags: FeatureFlag[]) => {
        this.state.update(state => ({
          ...state,
          flags: flags,
          loaded: true,
          error: null,
        }));
      },
      error: err => this.state.update(state => ({ ...state, error: err })),
    });
    this.fetchFromRemote$.pipe(takeUntilDestroyed()).subscribe((flag: FeatureFlag) => {
      this.hasFlagWithId(flag.id) ? this.edit$.next(flag) : this.add$.next(flag);
    });

    this.add$.pipe(takeUntilDestroyed()).subscribe((newFlag: FeatureFlag) => {
      this.state.update(state => ({
        ...state,
        flags: [...state.flags, newFlag],
      }));
    });

    this.edit$.pipe(takeUntilDestroyed()).subscribe((updatedFlag: FeatureFlag) => {
      this.state.update(state => ({
        ...state,
        flags: state.flags.map(flag => (flag.id === updatedFlag.id ? { ...flag, ...updatedFlag } : flag)),
      }));
    });

    // endregion

    effect(() => {
      // Store flags after they have loaded.
      if (this.flags().length) {
        this.saveFeatureFlags(this.flags());
      }
    });
  }

  // region private

  /**
   * Fetches all feature flags from App Configuration based on the labelFilter.
   */
  private fetchFeatureFlags(labelFilter: string): Observable<FeatureFlag> {
    return defer(() => this.appConfigurationClient.listConfigurationSettings({ labelFilter })).pipe(
      map(settings => JSON.parse(settings.value!)),
    );
  }

  /**
   * Checks if there exists a flag in storage with the given id in storage.
   * @param id of the flag we are looking for.
   */
  private hasFlagWithId(id: string): boolean {
    return this.flags().some(flag => flag.id === id);
  }

  // endregion

  // region storage

  private loadFeatureFlags() {
    const featureFlags = this.applicationStorageService.getItem('featureFlags');
    return of(featureFlags ? (featureFlags as FeatureFlag[]) : []);
  }

  private saveFeatureFlags(featureFlags: FeatureFlag[]) {
    this.applicationStorageService.setItem('featureFlags', featureFlags);
  }

  // endregion
}
